import en from "./locales/en.json";
import ptBR from "./locales/pt-BR.json";

export type MessageSchema = typeof en;

export default defineI18nConfig(() => ({
  legacy: false,
  locale: "en",
  strategy: "no_prefix",
  fallbackLocale: "en",
  locales: [
    {
      code: "en",
      name: "English",
    },
    {
      code: "pt-BR",
      name: "Brazilian Portuguese",
    },
  ],
  messages: {
    en,
    "pt-BR": ptBR,
  },
}));
